.App {
   text-align: center;
}

#root {
   height: 100%;
}

.slider {
   position: relative;
   height: 100vh;
   display: flex;
   justify-content: center;
   align-items: center;
   transition: all 3s;
}

.slide {
   opacity: 0;
   transition-duration: 1s;
}

.slide.active {
   opacity: 1;
   transition-duration: 1s;
   transform: scale(1.08);
}

.image {
   width: 1400px;
   height: 800px;
   border-radius: 10px;
}

.App-logo {
   height: 40vmin;
   pointer-events: none;
}

@keyframes fadeIn {
   0% {
      opacity: 0;
   }
   100% {
      opacity: 1;
   }
}

/* Opacity #1 */
/*.hover-footer-img img {*/
/*   opacity: 1;*/
/*   -webkit-transition: .3s ease-in-out;*/
/*   transition: .3s ease-in-out;*/
/*}*/

/*.hover-footer-img figure:hover img {*/
/*   opacity: .5;*/
/*}*/


@media (prefers-reduced-motion: no-preference) {
   .App-logo {
      animation: App-logo-spin infinite 20s linear;
   }
}

.App-header {
   background-color: #282c34;
   min-height: 100vh;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   font-size: calc(10px + 2vmin);
   color: white;
}

.App-link {
   color: #61dafb;
}

@keyframes App-logo-spin {
   from {
      transform: rotate(0deg);
   }
   to {
      transform: rotate(360deg);
   }
}

.herobg {
   min-height: -webkit-fill-available;
}

/*::selection {*/
/*   color: none;*/
/*   background: none;*/
/*}*/
/*!* For Mozilla Firefox *!*/
/*::-moz-selection {*/
/*   color: none;*/
/*   background: none;*/

.about-photo-container {
   transform:  translate(-50%);
}

.bg-line {
   background-image: url("https://shared-pw-fonts.s3.us-west-2.amazonaws.com/pw-icons-theme-8/info-a-vector.svg");
   background-repeat: no-repeat;
   height: 100%;
   position: absolute;
   top: 0;
   width: 100%;

   left: 0;
   z-index: 2;
}

.booking-bg-img {
   background-image: url("./img/booking/booking-bg-photo.jpg")
   /*background-image: url("./img/booking/booking-bg-photo.jpg")*/
}

@media (max-width: 768px) {
   .about-photo-container {
      transform: none;
   }
}

.turn-white {
   filter: brightness(0) invert(1);
}


 