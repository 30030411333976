@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:wght@400;700&family=Vollkorn:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Vollkorn:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
   font-family: 'Adine Kirnberg';
   src: url("./fonts/adine-kirnberg/AdineKirnberg.ttf"), url("./fonts/adine-kirnberg/AdineKirnberg-Alternate.ttf");
}

@font-face {
   font-family: Haigrast Serif;
   src: url("./fonts/haigrast-serif/haigrast-serif.otf"), url("./fonts/haigrast-serif/haigrast.otf");
}

@font-face {
   font-family: 'Brush Signature';
   src: url("fonts/brush-signature/BrushSignature.ttf");
}

@font-face {
   font-family: 'Brandon Grotesque';
   src: url("fonts/Brandon-Grotesque/Brandon_reg.otf"), url("fonts/Brandon-Grotesque/Brandon_reg_it.otf"),
         url("fonts/Brandon-Grotesque/Brandon_med.otf"), url("fonts/Brandon-Grotesque/Brandon_bld.otf"),
         url("fonts/Brandon-Grotesque/Brandon_blk.otf")
}


html, body {
   height: 100%;
   width: 100%;
   background-color: #F4F4F5;
   scroll-behavior: smooth;
}

* {
   box-sizing: border-box;
}

@layer components {
   .homepage {
      @apply font-heading;
   }


   .desktop-bg {
      background-image: url("./img/bg-maasai/maasai-bg-desktop-min.jpg");
   }

   .mobile-bg {
      background-image: url("./img/background/maasai-bg-mobile-min.jpg")
   }
   .wedding-package-bg{
      background-image: url("https://www.dropbox.com/s/74yfcudy5s0rsmt/IMG_9057-min-package-min.jpg?dl=1");
   }
   .corporates-package-bg{
      background-image: url("https://www.dropbox.com/s/ybllycxj80kwk6j/IMG_1333-min.jpg?dl=1");
   }

    .fashion-package-bg{
        background-image: url("https://www.dropbox.com/s/nut1kh2jg1e48zy/DJI_0459-min-min.jpg?dl=1");
    }
    .birthday-package-bg{
        background-image: url("https://www.dropbox.com/s/v9sz8lruptyo3rd/birthday.png?dl=1");
    }
   .back-image-sec3 {
      background: linear-gradient(rgba(0, 0, 0, 0.80), rgba(0, 0, 0, 0.80)), url("./img/weddingbrides.jpg") no-repeat;
   }

   .back-contact-img {
      background: url("./img/background/droan-boat-min.jpg") no-repeat;
   }

   .btnReadMore {
      background-color: #7D8F91;
   }

   .btnPortfolio {
      background-color: #93a2a2;
   }

   .mannuel-box-shadow {
      box-shadow: -60px 60px 0px 1px rgba(217, 220, 205, 0.75);
   }
}
