svg path {
   stroke-width: 1;
}

.react-player__play-icon {
   border-width: 0 !important;
   margin: 0 !important;
   width: 85px;
   height: 85px;
   border-radius: 50%;
   background-color: #efefef;
   display: flex;
   justify-content: center;
   align-items: center;
   box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
   opacity: 1;
   position: relative;
}

.react-player__play-icon:before {
   color: black;
   content: "Play";
   font-size: 20px;
   position: absolute;
}

.react-player__play-icon:hover {
   opacity: .5;
   transition: opacity 0.3s ease-in-out;
   cursor: pointer;
   background-color: #ddd;
}

.react-player__shadow {
   width: 100px !important;
   height: 100px !important;
}

/* CUSTOM CSS */

.player-container__darken {
   background-color: #000000;
   transition: color 0.3s ease-in-out;
}

.exit-button-desktop {
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   margin: auto;
   width: fit-content;
   height: fit-content;

   visibility: hidden;
   z-index: 999;
   transition: visibility 700ms;
}

.exit-button-desktop.show {
   visibility: visible;
   transform: translateY(-250%);
   animation: moveBtnLgUp 700ms;
}

.exit-button-desktop.hide {
   visibility: hidden;
   transform: translateY(0);
   animation: moveBtnLgDown 700ms;
}

#playButton {
   z-index: 9999;
   animation: showPlayBtn 700ms;
}

/* hide exit button on hover outside player container */
#player-container:not(:hover) .exit-button-desktop.hoverOutsidePlayer {
   visibility: hidden;
   transition: visibility 700ms;
   /*visibility: ;*/
   /*transform: translateY(0);*/
   animation: moveBtnLgDown 700ms;
}

/*#player-container:not(:focus-within) .exit-button-mobile {*/
/*   visibility: hidden;*/
/*   !*transform: translateY(-250%);*!*/
/*   !*transform: translateY(-11vh);*!*/
/*   !*animation: moveBtnSmUp 700ms;*!*/
/*}*/

@keyframes showExitBtn {
   0% {
      opacity: 0;
   }
   100% {
      opacity: 1;
   }
}

@keyframes moveBtnLgUp {
   0% {
      transform: translateY(0);
      opacity: 0;
   }
   100% {
      transform: translateY(-250%);
      opacity: 1;
   }
}

@keyframes moveBtnLgDown {
   0% {
      transform: translateY(-250%);
      opacity: 1;
   }
   100% {
      transform: translateY(0);
      opacity: 0;
   }
}

/*********************************************************************/
/* EXit button for Mobile */
/**********************************************************************/
.exit-button-mobile {
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   margin: auto;
   width: fit-content;
   height: fit-content;

   visibility: visible;
   z-index: 999;
   transition: visibility 700ms;
}

.exit-button-mobile.show {
   visibility: visible;
   /*transform: translateY(-250%);*/
   transform: translateY(-8vh);
   animation: moveBtnSmUp 700ms;
   opacity: 0.75;
}

.exit-button-mobile.show:hover {
   opacity: 1;
}

.exit-button-mobile.hide {
   visibility: hidden;
   transform: translateY(0);
   animation: moveBtnSmDown 700ms;
}

@keyframes moveBtnSmUp {
   0% {
      transform: translateY(0);
      opacity: 0;
   }
   100% {
      transform: translateY(-18vh);
      opacity: 0.75;
   }
}

@keyframes moveBtnSmDown {
   0% {
      transform: translateY(-18vh);
      opacity: 1;
   }
   100% {
      transform: translateY(0);
      opacity: 0;
   }
}
